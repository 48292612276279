<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-acc-menu></admin-acc-menu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>
                                <v-col>
                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:15%"/>
                                            <col style="width:85%"/>
                                        </colgroup>

                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    요일별 접속통계
                                </v-col>
                                <v-col cols="12" style="display: flex;">
                                    <select v-model="year"  @change="changeYear($event)" class="select-d">
                                        <option v-for="(n, i) in yearList" :value="n">{{n}}년</option>
                                    </select>
                                    <select v-model="month"  @change="changeMonth($event)" class="select-d ml-2">
                                        <option v-for="(n, i) in 12" :value="n">{{n}}월</option>
                                    </select>
                                </v-col>
                                <v-col cols="12">
                                    <span style="color: blue; font-weight: bold;">접속자수 : {{chartInfo.sum | comma}} 명</span>
                                </v-col>
                                <div class="overflow-x-auto">

                                    <div style="width: 900px; height: 450px;">
                                        <!--차트가 그려질 부분-->
                                        <canvas id="myChart"></canvas>
                                    </div>
                                </div>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import AdminAccMenu from '../../components/AdminAccMenu'

    export default {
        name: 'AdminAccWeek',
        components: {AdminAccMenu},
        data: () => ({
            year: "",
            month: "",
            yearList: [],
            statList: [],
            chartInfo: {
                label: [],
                data: [],
                sum: 0,
            },
            myChart: null,


        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            let now = new Date();	// 현재 날짜 및 시간
            let year = now.getFullYear();	// 연도
            let month = now.getMonth();
            this.year = String(year);
            this.month = String(month+1);

            for (year; year > 2020; year--) {

                this.yearList.push(year);
            }

            this.getList();

        },
        methods: {
            getList() {

                if (this.year.length !== 4) {
                    alert("잘못된 요청입니다.");
                    return;
                }

                let _this = this;

                let formdata = {};

                formdata['year'] = this.year;
                formdata['month'] = this.month;

                return this.$store.dispatch("acc/getAccWeek", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.statList = resp.message;

                            this.chartInfo.data = resp.data;
                            this.chartInfo.label = resp.label;

                            let _this = this;

                            this.chartInfo.data.forEach(function (e) {
                                _this.chartInfo.sum += e;
                            })

                            this.setChart();

                        }, 300)
                    })
                    .catch((err) => {
                    })


            },

            searchClick() {

                this.myChart.destroy();
                this.getList();

            },
            menuClick(route, roomGubun) {

                if (roomGubun != null) {
                    location.href = route + '?roomGubun=' + roomGubun;
                } else {
                    location.href = route;
                }

            },
            changeYear(event) {
                this.myChart.destroy();

                this.year = event.target.value;
                this.getList();

            },
            changeMonth(event) {
                this.myChart.destroy();

                this.month = event.target.value;
                this.getList();

            },
            setChart() {

                let _this = this;

                var context = document
                    .getElementById('myChart')
                    .getContext('2d');

                this.myChart = new Chart(context, {
                    type: 'bar', // 차트의 형태
                    data: { // 차트에 들어갈 데이터
                        labels: _this.chartInfo.label,
                        datasets: [
                            { //데이터
                                label: '접속자수', //차트 제목
                                //fill: false, // line 형태일 때, 선 안쪽을 채우는지 안채우는지

                                data: _this.chartInfo.data,
                                /*[
                                    21, 19, 25, 20, 23, 26, 25 //x축 label에 대응되는 데이터 값
                                ],*/
                                backgroundColor: [
                                    //색상
                                    'rgba(54, 162, 235, 0.8)',

                                ],
                                borderColor: [
                                    //경계선 색상
                                    'rgba(54, 162, 235, 1)',

                                ],
                                borderWidth: 1 //경계선 굵기
                            }
                        ]
                    },
                    options: {
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        scales: {
                            /*yAxes: [
                                {
                                    ticks: {
                                        suggestedMin: 0,
                                        beginAtZero: true,
                                        precision: 0,
                                        scaleIntegersOnly: true,
                                    }
                                }
                            ]*/
                            y: {
                                ticks: {
                                    precision: 0
                                }
                            }
                        },

                    }
                });


            }

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
            //resvConfirmInfo() { return this.$store.state.reservation.resvConfirmInfo }
        },
    }
</script>

<style>

</style>

